<template>
  <el-dialog :title="dialogType==='add'?'添加':dialogType==='look'?'查看':'编辑'" :before-close="close" :visible.sync="show" width="400px" center>
    <el-form v-if="show" :model="form" label-position="left" ref="form" :rules="rules">
      <template v-if="!isRouter">
        <el-form-item label="账号类型" :label-width="formLabelWidth" prop="account_type" style="width: 100%">
          <el-select v-model="form.account_type" placeholder="请选择" prop="account_type" :disabled="dialogType==='look'">
            <el-option label="员工" value="staff"></el-option>
            <el-option label="UP主" value="vlogger"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登陆账号" :label-width="formLabelWidth" prop="account">
          <el-input v-model="form.account" autocomplete="off"  :disabled="dialogType==='look'"></el-input>
        </el-form-item>
        <el-form-item label="登陆密码" :label-width="formLabelWidth" :prop="dialogType==='add'?'password':''">
          <el-input v-model="form.password" autocomplete="off" type="password" :disabled="dialogType==='look'"></el-input>
        </el-form-item>
        <el-form-item label="上传次数限制" v-if="form.account_type!='staff'" :label-width="formLabelWidth" prop="max_publish">
          <el-input v-model="form.max_publish" type="number"  :disabled="dialogType==='look'"></el-input>
        </el-form-item>
        <el-form-item v-if="dialogType=='edit'" label="状态" :label-width="formLabelWidth">
          <el-select v-model="form.status" placeholder="请选择" :disabled="dialogType==='look'">
            <el-option
              v-for="item in statusList"
              :label="item.label"
              :value="item.value"
              :key="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item label="UP主ID" :label-width="formLabelWidth" style="width: 100%" prop="vlogger_id">
          <el-select v-model="form.vlogger_id" placeholder="请选择UP主ID" prop="vlogger_id" :disabled="dialogType==='look'">
            <el-option :label="item.account" :key="item.id" :value="item.id" v-for="item in userList"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品ID" :label-width="formLabelWidth" prop="pro_id">
          <el-select v-model="form.pro_id" placeholder="请选择产品ID" prop="account_type" :disabled="dialogType==='look'">
            <el-option :label="item.product_name" :key="item.product_id" :value="item.product_id" v-for="item in projectList"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品用户ID" :label-width="formLabelWidth" prop="pro_user_id">
          <el-input v-model.number="form.pro_user_id" autocomplete="off" :disabled="dialogType==='look'"></el-input>
        </el-form-item>
        <el-form-item label="是否生效" :label-width="formLabelWidth">
          <el-switch  v-model="form.enable" :disabled="dialogType==='look'"></el-switch>
        </el-form-item>

      </template>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$parent.addFrom = false">取 消</el-button>
      <el-button type="primary" @click="submit" :disabled="dialogType==='look'">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import API from '@/api'
export default {
  name: "addForm",
  props:['show','projectList','userList','data','disabledType','dialogType'],
  data() {
    return {
      formLabelWidth:'110px',
      form:{
        status: true,
        account:'',
        password:'',
        account_type:'',
        max_publish:'',
      },
      rules: {
        account: [
          { required: true, message: '请输入账号内容', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9]+$/ ,message: '目前只支持字母和数字，不包括特殊字符', trigger: 'blur'}
        ],
        account_type: [
          { required: true, message: '请选择账号类型', trigger: 'change' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {  min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        vlogger_id: [
          { required: true, message: '请选择UP主ID', trigger: 'change' }
        ],
        pro_id: [
          { required: true, message: '请选择产品ID', trigger: 'change' }
        ],
        pro_user_id: [
          { required: true, message: '请输入产品用户ID', trigger: 'blur' }
        ],
        max_publish:[
          { required: true, message: '请输入上传次数限制', trigger: 'blur' },
        ]
      },
      statusList:[
        {label:'正常',value:true},
        {label:'禁用',value:false}
      ]
    }
  },
  created() {
    console.log(this.$route,"ROUTER")
  },
  watch:{
    data(data){
      this.form = data
      if(this.dialogType!=='add'){
        this.form.password = ""
      }
    },

  },
  computed:{
    isRouter:function(){
      return this.$route.path === '/videoProduction/association'
    }
  },
  methods:{
    submit(){
      this.$refs.form.validate((valid) => {
        if(valid){
          !this.form.id ? this.addUser() : this.update()
        }
      });
    },
    async update(){
       let json = this.isRouter?{
        enable: this.form.enable,
        id: this.form.id,
        pro_id: this.form.pro_id,
        pro_user_id: this.form.pro_user_id,
        vlogger_id: this.form.vlogger_id
       } :{
        account_type:this.form.account_type,
        id: this.form.id,
        max_publish: Number(this.form.max_publish),
        password: this.form.password,
        status: this.form.status,
        upload_privilege: this.form.upload_privilege
      };
      const {code,msg} = this.isRouter ?  await API.updateUserV2(json) : await API.updateUser(json) 
      if(!code){
        this.$message({ message: '更新成功！', type:'success'})
        this.$parent.closeAdd()
      }else{
        this.$message({ message: msg, type:'error'})
        this.$parent.closeAdd()
      }
    },
    async addUser(){
      let json = this.isRouter?{
        enable: this.form.enable ? this.form.enable : false,
        pro_id: this.form.pro_id,
        pro_user_id: this.form.pro_user_id,
        vlogger_id: this.form.vlogger_id
       } :{
        account: this.form.account,
        account_type: this.form.account_type,
        max_publish: Number(this.form.max_publish),
        password: this.form.password,
        status: this.form.status ? this.form.status : true,
        upload_privilege: this.form.upload_privilege ? this.form.upload_privilege : false
      };
      const {code,msg} = this.isRouter ?  await API.createdUpAss(json) :await API.createdUpAcc(json) 
      if(!code){
        this.$message({ message: '创建成功！', type:'success'})
        this.$parent.closeAdd()
      }else{
        this.$message({ message: msg, type:'error'})
        this.$parent.closeAdd()
      }
    },
    close() {
      this.$emit("clearData");
    },
  }
}
</script>

<style scoped type="scss">
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
::v-deep input[type="number"] {
    -moz-appearance: textfield;
}
::v-deep inpit {
    border: none
}
</style>
